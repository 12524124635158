var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card-text',[_c('v-row',[(_vm.permisos.includes('nom_tag_imagen_hotels:create') || _vm.permisos.includes('*'))?_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-btn',{staticClass:"mb-4 me-3",attrs:{"color":"primary","loading":_vm.loading},on:{"click":_vm.newItem}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiPlus))]),_c('span',[_vm._v(_vm._s(_vm.$t('btn.insert')))])],1)],1):_vm._e(),_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-text-field',{attrs:{"label":_vm.$t('lbl.itemsPerPage'),"type":"number","min":"5","hide-details":"","dense":"","outlined":""},on:{"input":function($event){return _vm.load()}},model:{value:(_vm.itemsPerPage),callback:function ($$v) {_vm.itemsPerPage=$$v},expression:"itemsPerPage"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"append-icon":_vm.icons.mdiMagnify,"label":_vm.$t('btn.search'),"single-line":"","hide-details":"","dense":"","outlined":""},on:{"input":function($event){return _vm.load()}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c('v-divider'),_c('v-form',{staticClass:"multi-col-validation px-5 py-5"},[_c('v-row',[_c('v-col',{staticClass:"text-right",attrs:{"cols":"12","md":"12"}},[_vm._v(" "+_vm._s(_vm.totalF)+" "+_vm._s(_vm.$t('lbl.de'))+" "+_vm._s(_vm.totalItems)+" "+_vm._s(_vm._f("lowercase")(_vm.$t('menu.tagsImg')))+" ")])],1)],1),(!_vm.isLoading)?_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-uppercase"},[_vm._v(" "+_vm._s(_vm.$t('lbl.name'))+" ")]),_c('th',{staticClass:"text-uppercase"},[_vm._v(" "+_vm._s(_vm.$t('lbl.description'))+" ")]),(
              _vm.permisos.includes('nom_tag_imagen_hotels:edit') ||
                _vm.permisos.includes('nom_tag_imagen_hotels:destroy') ||
                _vm.permisos.includes('*')
            )?_c('th',{staticClass:"text-uppercase"},[_vm._v(" "+_vm._s(_vm.$t('lbl.actions'))+" ")]):_vm._e()])]),_c('tbody',_vm._l((_vm.items),function(iten,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(_vm.getName(iten)))]),_c('td',[(iten.description)?_c('v-tooltip',{attrs:{"slot":"append-outer","color":"primary","top":"","content-class":"pa-0"},slot:"append-outer",scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" "+_vm._s(_vm.icons.mdiInformationOutline)+" ")])],1)]}}],null,true)},[_c('span',[_c('v-card',{staticClass:"mx-auto",attrs:{"max-width":"500"}},[_c('v-card-text',{staticClass:"primary"},[_c('p',{staticStyle:{"color":"#FFF"},domProps:{"innerHTML":_vm._s(("<i>" + (iten.description) + "</i>"))}})])],1)],1)]):_vm._e()],1),(
              _vm.permisos.includes('nom_tag_imagen_hotels:edit') ||
                _vm.permisos.includes('nom_tag_imagen_hotels:destroy') ||
                _vm.permisos.includes('*')
            )?_c('td',[(_vm.permisos.includes('nom_tag_imagen_hotels:edit') || _vm.permisos.includes('*'))?_c('v-tooltip',{attrs:{"top":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.editItem(iten)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiSquareEditOutline))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('btn.edit')))])]):_vm._e(),(_vm.permisos.includes('nom_tag_imagen_hotels:destroy') || _vm.permisos.includes('*'))?_c('v-tooltip',{attrs:{"top":"","color":"error"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.deleteItem(iten)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiDeleteOutline))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('btn.delete')))])]):_vm._e()],1):_vm._e()])}),0)]},proxy:true}],null,false,1123282395)}):_vm._e(),_c('v-card-text',{staticClass:"pt-2"},[_c('v-row',[_c('v-col',{staticClass:"d-flex justify-end",attrs:{"lg":"12","cols":"12"}},[_c('v-pagination',{attrs:{"length":_vm.pagination.total,"total-visible":"5"},on:{"input":_vm.onPageChange},model:{value:(_vm.pagination.current),callback:function ($$v) {_vm.$set(_vm.pagination, "current", $$v)},expression:"pagination.current"}})],1)],1)],1),_c('v-dialog',{attrs:{"scrollable":"","max-width":"350px"},model:{value:(_vm.isDialogVisibleDelete),callback:function ($$v) {_vm.isDialogVisibleDelete=$$v},expression:"isDialogVisibleDelete"}},[_c('v-card',{attrs:{"max-height":"400px"}},[_c('v-card-title',[_vm._v(_vm._s(((_vm.$t('btn.delete')) + " " + (_vm.item.name))))]),_c('v-divider'),_c('v-card-text',[_c('p',{staticClass:"mt-5"},[_vm._v(" "+_vm._s(_vm.$t('msg.deleteItem', { n: _vm.item.name }))+" ")])]),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary","outlined":""},on:{"click":function($event){_vm.isDialogVisibleDelete = !_vm.isDialogVisibleDelete}}},[_vm._v(" "+_vm._s(_vm.$t('btn.close'))+" ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"error","outlined":"","loading":_vm.loading},on:{"click":function($event){return _vm.confirmDelete()}}},[_vm._v(" "+_vm._s(_vm.$t('btn.send'))+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }